import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:before-fetch-request', (event) => {
      const { headers } = event.detail.fetchOptions || {};
      if (headers) {
        headers.Accept = ['text/vnd.turbo-stream.html', headers.Accept].join(
          ', '
        );
      }
    });
  }

  submit() {
    this.element.requestSubmit();
  }

  toggleForm() {
    const form = this.element.querySelector('.update-form');
    if (form) {
      form.classList.toggle('hidden');
    }
  }
}

/* eslint-disable no-undef */
/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// import Rails from 'rails-ujs';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap';

//import "@hotwired/turbo-rails";
import { Turbo } from '@hotwired/turbo-rails';
Turbo.session.drive = false;
//import Rails from "@rails/ujs";
//import * as ActiveStorage from '@rails/activestorage';

//Rails.start()
//ActiveStorage.start();

import { Application } from '@hotwired/stimulus';

import Choices from 'choices.js';

// Stimulus setup
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
window.Stimulus = application;

const BotDetector = require('device-detector-js/dist/parsers/bot');
window.botDetector = new BotDetector();

import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();

require('trix');
require('@rails/actiontext');
require('@popperjs/core');

window.initMap = function (...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-callback', true, true);
  event.args = args;
  window.dispatchEvent(event);
};

document.addEventListener('DOMContentLoaded', () => {
  const advancedSelect = document.querySelector('.advanced-select');
  if (advancedSelect) {
    new Choices(advancedSelect);
  }
});

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {   
  static targets = [ "toggleable" ]         

  toggle() {
    this.toggleableTarget.classList.toggle('hidden')
    document.getElementById("bg-blur").classList.remove("bg-gray-600")
  }    
}

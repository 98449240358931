import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.setMaxHeight();
  }

  setMaxHeight() {
    let maxHeight = 0;

    this.itemTargets.forEach((item) => {
      let itemHeight = item.offsetHeight;
      if (itemHeight > maxHeight) {
        maxHeight = itemHeight;
      }
    });

    // Set all items to the maximum height
    this.itemTargets.forEach((item) => {
      item.style.height = `${maxHeight}px`;
    });
  }
}

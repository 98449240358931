import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'field',
    'street_address',
    'city',
    'state',
    'zip_code',
    'latitude',
    'longitude',
  ]; // "map"

  connect() {
    if (typeof google != 'undefined') {
      this.initMap();
    }
  }

  initMap() {
    // this.map = new google.maps.Map(this.mapTarget, {
    //   center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
    //   zoom: (this.data.get("latitude") == null ? 4 : 15)
    // })

    var options = {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.fieldTarget,
      options
    );

    //this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields(['address_components', 'geometry']); // 'icon', 'name'
    this.autocomplete.addListener(
      'place_changed',
      this.placeChanged.bind(this)
    );

    document
      .getElementById('cart_mailing_address_attributes_street_address')
      .setAttribute('autocomplete', '1j48342j');

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
    });
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    // console.log(place.formatted_address)

    // console.log(place.address_components)

    // Get the place details from the autocomplete object.
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
      postal_code_suffix: 'long_name',
    };

    const componentFormElements = {
      locality: 'cart_mailing_address_attributes_city',
      administrative_area_level_1: 'cart_mailing_address_attributes_state',
      postal_code: 'cart_mailing_address_attributes_zip_code',
      //postal_code_suffix: "long_name"
    };

    const hiddenComponentFormElements = {
      locality: 'mailing_address_city',
      administrative_area_level_1: 'mailing_address_state',
      postal_code: 'mailing_address_zip_code',
      //postal_code_suffix: "long_name"
    };

    let street_number = '';
    let street_name = '';

    // for (const component in componentForm) {
    //   document.getElementById(component).value = ""
    //   document.getElementById(component).disabled = false
    // }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    for (const component of place.address_components) {
      const addressType = component.types[0];

      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]];
        const componentFormElement = componentFormElements[addressType];
        const hiddenComponentFormElement =
          hiddenComponentFormElements[addressType];

        if (addressType == 'street_number') {
          if (typeof val != 'undefined') {
            street_number = val;
          }
        } else if (addressType == 'route') {
          if (typeof val != 'undefined') {
            street_name = val;
          }
        } else {
          if (componentFormElement != null) {
            document.getElementById(componentFormElement).value = val;
            document
              .getElementById(componentFormElement)
              .classList.remove('empty');
          }
          if (hiddenComponentFormElement != null) {
            var ele = document.getElementById(hiddenComponentFormElement);
            if (ele) {
              ele.value = val;
            }
          }
        }
      }
    }

    document.getElementById(
      'cart_mailing_address_attributes_street_address'
    ).value = `${street_number} ${street_name}`;
    var hiddenStreet = document.getElementById(
      'mailing_address_street_address'
    );
    if (hiddenStreet) {
      hiddenStreet.value = `${street_number} ${street_name}`;
    }

    window.check_address();

    // if (!place.geometry) {
    //   window.alert(`No details available for input: ${place.name}`)
    //   return
    // }

    // if (place.geometry.viewport) {
    //   this.map.fitBounds(place.geometry.viewport)
    // } else {
    //    this.map.setCenter(place.geometry.location)
    //    this.map.setZoom(17)
    // }

    // this.marker.setPosition(place.geometry.location)
    // this.marker.setVisible(true)

    var latField = document.getElementById('mailing_address_latitude');

    if (place.geometry) {
      if (latField) {
        document.getElementById(
          'mailing_address_latitude'
        ).value = `${place.geometry.location.lat()}`;
        document.getElementById(
          'mailing_address_longitude'
        ).value = `${place.geometry.location.lng()}`;
      } else if (this.latitudeTarget) {
        this.latitudeTarget.value = place.geometry.location.lat();
        this.longitudeTarget.value = place.geometry.location.lng();
      }
    }
  }

  keydown(event) {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'targetDiv'];

  connect() {
    this.toggleDiv();
    console.log('Hello, Stimulus!', this.checkboxTargets);
    console.log('Hello, Stimulus!', this.targetDivTarget);
  }

  toggleDiv() {
    const anyChecked = this.checkboxTargets.some(
      (checkbox) => checkbox.checked
    );
    this.targetDivTarget.classList.toggle(
      'm-survey__callout-container--hidden',
      !anyChecked
    );
  }
}

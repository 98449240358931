import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'field',
    'street_address',
    'city',
    'state',
    'zip_code',
    'latitude',
    'longitude',
  ];

  connect() {
    if (typeof google != 'undefined') {
      this.initMap();
    }
  }

  initMap() {
    const options = {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.fieldTarget,
      options
    );
    this.autocomplete.setFields(['address_components', 'geometry']);
    this.autocomplete.addListener(
      'place_changed',
      this.placeChanged.bind(this)
    );

    document
      .getElementById('sample_request_form_street_address')
      .setAttribute('autocomplete', '1j48342j');

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
    });
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    this.fillAddressFields(place);
  }

  fillAddressFields(place) {
    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name',
    };

    const componentFormElements = {
      locality: 'sample_request_form_city',
      administrative_area_level_1: 'sample_request_form_state',
      postal_code: 'sample_request_form_zip_code',
    };

    let street_number = '';
    let street_name = '';

    for (const component of place.address_components) {
      const addressType = component.types[0];

      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]];
        const componentFormElement = componentFormElements[addressType];

        if (addressType == 'street_number') {
          street_number = val;
        } else if (addressType == 'route') {
          street_name = val;
        } else if (componentFormElement) {
          const elem = document.getElementById(componentFormElement);
          if (elem) {
            elem.value = val;
            elem.classList.remove('empty');
          }
        }
      }
    }

    document.getElementById(
      'sample_request_form_street_address'
    ).value = `${street_number} ${street_name}`;
  }

  keydown(event) {
    if (event.key == 'Enter') {
      event.preventDefault();
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, refreshInterval: Number }

  initialize() {
  	this.imageURL = ""
    this.refreshes = 0
  }

  connect() {
    this.load()

    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    fetch(this.urlValue)
      .then(response => response.json()) // text()
      .then(data => this.replaceImage(data))
      .then(this.refreshes++)
      //.then(html => this.element.innerHTML = html)
  }

  addImage(src_url, selector_class) {
    const image = document.createElement('img')
    image.src = src_url
    document.querySelector(selector_class).innerHTML = ''
    document.querySelector(selector_class).appendChild(image)
  }

  replaceImage(data) {
  	//console.log(data)

    // Add text for long loading
    if (this.refreshes > 2) {
      document.getElementById("preview-text-2").innerHTML = "Still loading, just another 15 seconds..."
    }

  	if (this.imageURL != data.url && data.url != null & data.url != "") {

      if (data.bg_removal == "pending") {
        document.getElementById("preview-text-2").innerHTML = "Just another 15 seconds..."
      } else if (data.bg_removal == "complete") {
        document.getElementById("mini-preview").classList.remove("no-image")
        document.getElementById("preview-text").classList.remove("hidden")
        this.addImage(data.url, '.mini-preview')
        this.stopRefreshing()
      } 	      

  	}

  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}

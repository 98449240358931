import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hiddenContent", "link"];

  connect() {
    this.visible = false;
    if (this.hasHiddenContentTarget) {
      this.hiddenContentTarget.hidden = true;
    }
    if (this.hasLinkTarget) {
      this.linkTarget.innerHTML = this.linkText;
    }
  }

  get linkText() {
    return this.visible ? "hide" : "show";
  }

  toggle(event) {
    event.preventDefault();
    this.visible = !this.visible;

    if (this.hasLinkTarget) {
      this.linkTarget.innerHTML = this.linkText;
    }

    if (this.hasHiddenContentTarget) {
      if (this.visible) {
        this.hiddenContentTarget.removeAttribute("hidden");
      } else {
        this.hiddenContentTarget.hidden = true;
      }
    }
  }
}

// app/javascript/controllers/sliding_panel_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['panel', 'toggleButton'];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggle() {
    this.panelTarget.classList.toggle('active');
  }

  handleClickOutside(event) {
    if (
      !this.panelTarget.contains(event.target) &&
      !this.toggleButtonTarget.contains(event.target)
    ) {
      this.panelTarget.classList.remove('active');
    }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitButton', 'oneOffPrice'];
  static values = {
    oneOffButtonLabel: String,
    subButtonLabel: String
  }

  connect() {
    this.selectSubscribe();
  }

  selectOneOff() {
    this.submitButtonTarget.value = this.oneOffButtonLabelValue;
    this.oneOffPriceTarget.classList.remove('opacity-0');
  }

  selectSubscribe() {
    this.submitButtonTarget.value = this.subButtonLabelValue;
    this.oneOffPriceTarget.classList.add('opacity-0');
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['refreshLink'];

  refreshPage() {
    document.addEventListener(
      'ajax:success',
      function () {
        location.reload();
      },
      { once: true }
    );
  }
}

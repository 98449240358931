import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['total', 'quantity', 'sku', 'protein'];
  static outlets = ['sku-selector'];

  connect() {
    this.quantity = this.quantityTarget.value;
    this.skuPrice =
      this.skuTarget.querySelector('option:checked').dataset.price;
    this.isSelected = this.proteinTarget.checked;
    this.toggleFormElements();
    this.updateTotal();
  }

  sumTotal() {
    let total = 0;
    this.skuSelectorOutlets.forEach((skuSelector) => {
      total += skuSelector.totalPrice();
    });

    const originalTotal = total;
    const originalTotalDisplayElement =
      document.querySelector('#totalSumDisplay');
    if (originalTotalDisplayElement) {
      originalTotalDisplayElement.textContent = `${originalTotal.toLocaleString(
        'en-US',
        { style: 'currency', currency: 'USD' }
      )}`;
    }

    const couponElement = document.querySelector('#coupon-amount');
    let couponAmount = 0;
    let couponPercentage = 0;

    if (couponElement) {
      if (couponElement.dataset.amount) {
        couponAmount = parseFloat(
          couponElement.dataset.amount.replace(/[^0-9.-]+/g, '')
        );
      } else if (couponElement.dataset.percentage) {
        couponPercentage = parseFloat(
          couponElement.dataset.percentage.replace(/[^0-9.-]+/g, '')
        );
      }
    }

    let discountAmount = couponAmount;
    if (couponPercentage > 0) {
      const discount = total * (couponPercentage / 100);
      discountAmount += discount;
      total -= discount;
    } else {
      total -= couponAmount;
    }

    const discountDisplayElement = document.querySelector(
      '#discountAmountDisplay'
    );
    if (discountDisplayElement) {
      discountDisplayElement.textContent = `-${discountAmount.toLocaleString(
        'en-US',
        { style: 'currency', currency: 'USD' }
      )}`;
    }

    const discountedTotalDisplayElement = document.querySelector(
      '#discountedTotalDisplay'
    );
    if (discountedTotalDisplayElement) {
      discountedTotalDisplayElement.textContent = `${total.toLocaleString(
        'en-US',
        { style: 'currency', currency: 'USD' }
      )}`;
    }
  }

  updateTotal() {
    const price = this.totalPrice();
    this.totalTarget.textContent = price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    this.sumTotal();
  }

  totalPrice() {
    if (!this.quantity || !this.skuPrice || !this.isSelected) return 0;
    return this.quantity * this.skuPrice;
  }

  updateQuantity(e) {
    this.quantity = e.target.value;
    this.updateTotal();
  }

  updateSku(e) {
    this.skuID = e.target.value;
    this.skuPrice = e.target.querySelector('option:checked').dataset.price;
    this.updateTotal();
  }

  selectProtein(e) {
    this.isSelected = e.target.checked;
    this.quantityTarget.value = e.target.checked ? 1 : 0;
    this.updateQuantity({ target: { value: this.quantityTarget.value } });
    this.toggleFormElements();
    this.updateTotal();
  }

  toggleFormElements() {
    const formElements = [this.quantityTarget, this.skuTarget];
    formElements.forEach((element) => {
      element.disabled = !this.isSelected;
    });
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['surveyForm', 'surveyErrors', 'primaryRadio', 'submitButton'];

  select() {
    if (!this.validatePrimaryReason()) return;
    this.submitButtonTarget.removeAttribute('disabled');
  }

  validatePrimaryReason() {
    return this.primaryRadioTargets.some(checkbox => checkbox.checked);
  }

  onPostSuccess(event) {
    let [_data, _status, xhr] = event.detail;

    this.surveyFormTarget.innerHTML = xhr.response;

    let inputField = document.getElementById(
      'attribution_survey_secondary_reason'
    );

    if (inputField) inputField.focus();

    let otherInputField = document.getElementById(
      'attribution_survey_tertiary_reason'
    );

    if (otherInputField) otherInputField.focus();

    // The following code will dismiss the survey modal when the user clicks on
    // either the secondary or teriary submit button
    const tertiaryRadioButtons = document.querySelectorAll(
      '.js-tertiary-check input[type="radio"]'
    );

    const tertiarySubmitButton = document.querySelector(
      '.js-tertiary-submit input[type="submit"]'
    );

    tertiaryRadioButtons.forEach(function(radioButton) {
      radioButton.addEventListener('click', function () {
        if (radioButton.checked) {
          if (
            radioButton.value === 'Someone I follow' ||
            radioButton.value === 'Other'
          ) {
            tertiarySubmitButton.removeAttribute('data-bs-dismiss');
          }
        }
      });
    });
  }

  onPostError() {}
}

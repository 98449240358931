import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit() {
    const url = new URL(window.location.href);
    const frame = document.getElementById('content_toggle');
    let toggle = url.searchParams.get('toggle') == 'alt' ? 'default' : 'alt';
    url.searchParams.set('toggle', toggle);
    frame.src = url;
    frame.reload();
    window.history.pushState(null, '', url);
  }
}

import { Controller } from '@hotwired/stimulus';
import Choices from 'choices.js';
import _ from 'lodash';

export default class extends Controller {
  connect() {
    this.searchElement = this.element.querySelector('.coupon-select');
    this.setupChoices();
    this.addEventListeners();
  }

  setupChoices() {
    this.searchComponent = new Choices(this.searchElement, {
      removeItemButton: true,
      searchEnabled: true,
      searchChoices: false,
    });

    const choiceId = this.element.dataset.choiceId;
    const choiceText = this.element.dataset.choiceText;

    if (choiceId && choiceText) {
      const choice = {
        id: choiceId,
        text: choiceText,
        selected: true,
      };
      this.searchComponent.setChoices([choice], 'id', 'text', false);
    }
  }

  addEventListeners() {
    this.searchElement.addEventListener(
      'search',
      _.debounce((event) => {
        this.search(event.detail.value);
      }, 300)
    );
  }

  async search(query) {
    const url = `${this.element.dataset.url}?q=${query}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const json = await response.json();
    this.searchComponent.setChoices(json, 'id', 'text', true);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['dogAge', 'dogAgeUnit', 'dogBreed', 'dogActivityLevel', 'dogWeight'];

  connect() {
    const $age = this.dogAgeTarget;
    $age.focus();
  }

  enableContinue() {
    const $age = this.dogAgeTarget;
    const $ageUnit = this.dogAgeUnitTarget;
    const $activityLevel = this.dogActivityLevelTarget;
    const $weight = this.dogWeightTarget;
    const $submitButton = this.element.querySelector('input[type=submit]');

    if ($age.value && $ageUnit.value && $activityLevel.value && $weight.value) {
      $submitButton.removeAttribute('disabled');
    } else {
      $submitButton.setAttribute('disabled', 'disabled');
    }
  }
}

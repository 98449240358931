import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit() {
    const quantity = this.element.value;
    const url = new URL(window.location.href);
    const frame = document.getElementById('one-time-purchase');

    url.searchParams.set('quantity', quantity.toString());
    frame.src = url;
    frame.reload();
    window.history.pushState(null, '', url);
  }
}

import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "select", "reviews" ]

  connect() {
    console.log("Review controller connected!")
  }

  updateReviews() {
    let rating = this.selectTarget.value

    Rails.ajax({
      url: "/reviews",
      type: "GET",
      data: `rating=${rating}`,
      dataType: "script",
      error: (data) => {
        console.error("Error fetching reviews: ", data);
      }
    })
  }
}

// app/javascript/controllers/sticky__nav_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element', 'text'];
  static values = { stickyNavClass: String };

  connect() {
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll);
    this.calculateInitialOffset();
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  calculateInitialOffset() {
    this.initialOffset =
      this.elementTarget.getBoundingClientRect().top + window.scrollY;
  }

  handleScroll() {
    if (window.scrollY > this.initialOffset) {
      this.elementTarget.classList.add(this.stickyNavClassValue);
      this.textTarget.textContent = 'Build Your Plan';
    } else {
      this.elementTarget.classList.remove(this.stickyNavClassValue);
      this.textTarget.textContent = 'Get a Custom Plan';
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['userFirstName', 'userLastName', 'userEmail'];

  connect() {
    const $firstName = this.userFirstNameTarget;
    $firstName.focus();
  }

  enableContinue() {
    const $firstName = this.userFirstNameTarget;
    const $lastName = this.userLastNameTarget;
    const $email = this.userEmailTarget;
    const $submitButton = this.element.querySelector('input[type=submit]');

    if ($firstName.value && $lastName.value && $email.value && this.validEmail($email.value)) {
      $submitButton.removeAttribute('disabled');
    } else {
      $submitButton.setAttribute('disabled', 'disabled');
    }
  }

  validEmail(email) {
    if (!email) return false;
    return /^\S+@\S+\.\S+$/.test(email);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.checkCart();
  }

  checkCart() {
    const orderItems = this.element.querySelectorAll('.cart-item');
    const payNowButton = document.getElementById('submit-payment');

    if (orderItems.length === 0) {
      payNowButton.classList.add('disabled');
      payNowButton.disabled = true;
    } else {
      payNowButton.classList.remove('disabled');
      payNowButton.disabled = false;
    }
  }
}

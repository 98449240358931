import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu', 'toggleButton', 'icon', 'nav'];

  connect() {
    window.addEventListener('resize', this.resetMenu.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.resetMenu.bind(this));
  }

  toggle() {
    const isHidden = this.menuTarget.classList.toggle('visually-hidden');

    if (isHidden) {
      this.iconTarget.classList.remove('close');
      this.iconTarget.classList.add('hamburger');
    } else {
      this.iconTarget.classList.remove('hamburger');
      this.iconTarget.classList.add('close');
    }
  }

  resetMenu() {
    if (window.innerWidth >= 992) {
      this.menuTarget.classList.add('visually-hidden');
      this.iconTarget.classList.remove('close');
      this.iconTarget.classList.add('hamburger');
    }
  }
}
